import React from 'react';
import config from '../../config';

export default function Footer() {
    return (
        <footer id="footer">
            <ul className="icons">
                {config.socialLinks.map(social => {
                    const {style, icon, name, url} = social;
                    return (
                        <li key={url}>
                            <a href={url} className={`icon ${style} ${icon}`}>
                                <span className="label">{name}</span>
                            </a>
                        </li>
                    );
                })}
            </ul>
            <div className="madeWithLove">Made with <span className="love">♥</span> in Stuttgart, Germany</div>
            <ul className="copyright">
                <li>©Copyright 2020 - {new Date().getFullYear()}, Timo Buhmann, Stuttgart, Germany</li>
                <li>
                    hosted by: <a href="https://www.teqneers.de/">TEQneers Gmbh & Co. KG</a>
                </li>
            </ul>
        </footer>
    );
}
