module.exports = {
  siteTitle: 'Timo Buhmann – digital transformer / contributor / sidemount diver / dad',
  manifestName: 'Spectral',
  manifestShortName: 'Landing',
  manifestStartUrl: '/',
  manifestBackgroundColor: '#397885',
  manifestThemeColor: '#397885',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/gatsby-starter-spectral/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'Timo Buhmann',
  subHeading: 'Digital Transformer, Contributor, Sidemount Scuba Diver & Dad',
  // social
  socialLinks: [
    {
      style: 'brands',
      icon: 'fa-facebook',
      name: 'Facebook',
      url: 'https://www.facebook.com/buhmanntimo',
    },
    {
      style: 'brands',
      icon: 'fa-linkedin',
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/buhmann/',
    },
    {
      style: 'brands',
      icon: 'fa-youtube',
      name: 'YouTube',
      url: 'https://www.youtube.com/@butimo',
    },
  ],
};
